import Head from 'next/head';
import React from 'react';

import type { Route } from 'nextjs-routes';

import useAdblockDetect from 'lib/hooks/useAdblockDetect';
import useGetCsrfToken from 'lib/hooks/useGetCsrfToken';
import * as metadata from 'lib/metadata';
import * as mixpanel from 'lib/mixpanel';
import { init as initSentry } from 'lib/sentry/config';

type Props = Route & {
  children: React.ReactNode;
}

initSentry();

const PageNextJs = (props: Props) => {
  const { title, description, opengraph } = metadata.generate(props);

  useGetCsrfToken();
  useAdblockDetect();

  const isMixpanelInited = mixpanel.useInit();
  mixpanel.useLogPageView(isMixpanelInited);

  return (
    <>
      <Head>
        <title>NXTChain Explorer</title>
        <meta name="description" content="NXTChain blockchain explorer. NXTChain stands as the top-tier enterprise blockchain in the current landscape. Many projects leveraging NXTChains robust data availability, APIs, and ecosystem tools, NXTChain ensures reliable network support. Explore blockchain data, tokens, decentralized applications, charts, and more with ease. Discover the power of NXTChain."/>

        { /* OG TAGS */ }
        <meta property="og:title" content="NXTChain Explorer"/>
        { opengraph.description && <meta property="og:description" content="NXTChain blockchain explorer. NXTChain stands as the top-tier enterprise blockchain in the current landscape. Many projects leveraging NXTChains robust data availability, APIs, and ecosystem tools, NXTChain ensures reliable network support. Explore blockchain data, tokens, decentralized applications, charts, and more with ease. Discover the power of NXTChain."/> }
        <meta property="og:image" content="https://nxtscan.com/assets/nxt-logo.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:image" content="https://nxtscan.com/assets/nxt-logo.png"/>
        <meta property="og:type" content="website"/>
      </Head>
      { props.children }
    </>
  );
};

export default React.memo(PageNextJs);
